import React, { useState } from "react"
import './newsletter.css'
import styled from "styled-components"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { FaCircle, IoIosMail } from "react-icons/all"

const WhiteCircle = styled(FaCircle)`
    color: #ffffff;
    font-size: 125px;
    position: absolute;
    top: -68px;
    align-self: flex-end;
`;
const Circle = styled(FaCircle)`
    color: #94040b;
    font-size: 100px;
    position: absolute;
    align-self: flex-end;
`;
const Mail = styled(IoIosMail)`
    color: #ffffff;
    font-size: 60px;
    position: absolute;
    align-self: flex-end;
    top: -34px;
`;

const Button = styled.button`
  padding: 15px 25px 17px 25px;
  text-transform: uppercase;
  background-color: #94040b;
  color: #ffffff;
  border: none;
  border-radius: 50px;
  outline: none;
`

const Subscribe = styled.button`
  margin-top: 40px;
  padding: 2px 30px;
  background-color: #94040b;
  color: #ffffff;
  border: none;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 600;
  outline: none;
`

const Newsletter = () => {

  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');


  const handleSubmit = e => {
    e.preventDefault();
    addToMailchimp(email, {
      NAME: name,
      COUNTRY: country,
      COMPANY: company
    })
      .then((data) => {
        if (data.result === "success") {
          alert("Thanks for Subscribing")
        } else {
          alert("Error! Please try again")
        }
      })
      .catch((err) => {
        // Errors in here are client side
        // Mailchimp always returns a 200
        console.log(err)
      });
  };

  const handleNameChange = event => {
    setName(event.currentTarget.value);
  };

  const handleCompanyChange = event => {
    setCompany(event.currentTarget.value);
  };

  const handleEmailChange = event => {
    setEmail(event.currentTarget.value);
  };

  const handleCountryChange = event => {
    setCountry(event.currentTarget.value);
  };


  return (
    <div>
      <div className="container-fluid newsletter-div d-inline-flex flex-column justify-content-center align-items-center">
        <div className="row">
          <div className="col py-5">
            <Button data-toggle="modal" data-target="#exampleModalCenter">Subscribe to newsletter</Button>
          </div>
        </div>
      </div>

      <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header justify-content-center border-bottom-0">
              <WhiteCircle /><Circle /><Mail />
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <h4 className="modal-title mx-auto my-5" id="exampleModalCenterTitle">GET OUR NEWSLETTER</h4>
              <div className="container center">
                <div className="row w-100 center">
                  <form className="w-75" onSubmit={handleSubmit}>
                    <div className="form-group font-italic">
                    {/* <label>First name:</label>  */}
                      <input name={"name"} type="text" onChange={handleNameChange} className="form-control" placeholder={"Your Name"} required/>
                    </div>
                    <div className="form-group font-italic">
                      <input name={"company"} type="text" onChange={handleCompanyChange} className="form-control" placeholder={"Your Company Name"} required/>
                    </div>
                    <div className="form-group font-italic">
                      <input name={"email"} type="email" onChange={handleEmailChange} className="form-control" placeholder={"Your Email"} required/>
                    </div>
                    <div className="form-group font-italic">
                      <input name={"country"} type="text" onChange={handleCountryChange} className="form-control" placeholder={"Your Country"} required/>
                    </div>
                    <Subscribe type={"submit"}>Subscribe</Subscribe>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


  )
}
export default Newsletter
