import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import "./Footer.css"
import styled from 'styled-components'
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa'

const Facebook = styled(FaFacebookF)`
    color: #ffffff;
    float: left;
    font-size: 25px;
    margin: 0% 5%;
`;
const LinkedinIn = styled(FaLinkedinIn)`
    color: #ffffff;
    float: left;
    font-size: 25px;
    margin: 0% 5%;
`;

const FooterComponent = () => {

    const data = useStaticQuery(graphql`
    query {
        contentfulFooter {
            text
            facebook
            linkedIn
            termsAndConditions {
              childContentfulRichText {
                html
              }
            }
            privacyPolicy {
              childContentfulRichText {
                html
              }
            }
          }
      }
    `)

    const text = data.contentfulFooter.text

    return (
        <footer className="page-footer font-small pt-4">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-6 footer-copyright text-left pt-4 d-md-block d-lg-block d-flex justify-content-center" style={{ paddingLeft: "0px" }}>

                        <Link to="/OurOffices" className="pr-md-0 mb-2" style={{ color: "white", float: `left`, marginRight: `1%` }}>
                            Contact Us
                      </Link>
                      <div className="mar">
                        <a href="https://www.facebook.com/Ijaz-Ahmed-Associates-176496929052685/?ti=as" target="_blank" rel="noopener noreferrer"><Facebook /></a>
                        <a href=" https://www.linkedin.com/company/ijaz-ahmed-associates"><LinkedinIn /></a>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 footer-copyright text-right pt-4 mb-4 mb-lg-0 mb-md-0 mb-sm-4 mb-xl-0 d-md-block d-lg-block d-flex justify-content-center align-items-center" style={{ height: 63, paddingRight: '0px' }}>

                        <button data-toggle="modal" data-target="#termsAndConditions" className="T-and-C" style={{ color: `white`, display: "inline-block", background: "none", border: "none", padding: 0, outline: "none" }}>
                            Terms & Conditions
                      </button>

                        <div className="modal fade" id="termsAndConditions" tabIndex="-1" role="dialog" aria-labelledby="termsAndConditions" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-scrollable" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h2 className="modal-title ml-auto text-center" id="exampleModalScrollableTitle">Terms & Conditions</h2>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body text-justify text-dark">
                                        <p style={{ fontSize: 20 }} dangerouslySetInnerHTML={{ __html: data.contentfulFooter.termsAndConditions.childContentfulRichText.html }} />
                                    </div>
                                    {/* <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                        {' | '}

                        <button data-toggle="modal" data-target="#privacyPolicy" className="T-and-C" style={{ color: `white`, display: "inline-block", background: "none", border: "none", padding: 0, outline: "none" }}>
                            Privacy Policy
                      </button>

                        <div className="modal fade" id="privacyPolicy" tabIndex="-1" role="dialog" aria-labelledby="privacyPolicy" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-scrollable" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h2 className="modal-title ml-auto text-center" id="exampleModalScrollableTitle">Privacy Policy</h2>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body text-justify text-dark">
                                        <p dangerouslySetInnerHTML={{ __html: data.contentfulFooter.privacyPolicy.childContentfulRichText.html }} />
                                    </div>
                                    {/* <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="w-100">
                        <hr style={{
                            marginTop: 0,
                            marginBottom: "2rem",
                            borderTop: "1px solid rgb(255,255,255)"
                        }} />
                    </div>
                    <div className="col-sm-12 col-md-12 footer-copyright text-center sm-center">
                        <div dangerouslySetInnerHTML={{ __html: text }}/>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterComponent;
