import React from "react"
import './layout.css'
import styled from "styled-components"
import FooterComponent from "./footer/Footer"
import NavComponent from "./navbar/Navbar"
import Newsletter from "./Newsletter/Newsletter"
import { graphql, useStaticQuery } from "gatsby"


const SubDiv = styled.div`
  display: "inline-block";
`
const Layout = ({children}) => {
  const data = useStaticQuery(graphql`
    query {
        contentfulMainLogoAndBackgroundImage(path: {eq: "/home"}) {
        backgroundImage {
          fluid(quality:100) {
            src
          }
        }
      }
    }
  `)
  const MainDiv = styled.div`
  background-image: url(${data.contentfulMainLogoAndBackgroundImage.backgroundImage.fluid.src});
  background-color: rgba(249,224,177,0.7);
  object-fit: cover;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
`
    return (
      <>

      <div>
        <MainDiv>
          <NavComponent />
          <SubDiv>
            <main>{children}</main>
          </SubDiv>
          <Newsletter/>
          <FooterComponent/>
        </MainDiv>
      </div>
      </>
    )
}

export default Layout