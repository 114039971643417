import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Navbar, Nav } from "react-bootstrap"
import "./navbar.css"


const NavComponent = () => {
  const data = useStaticQuery(graphql`
    query {
        contentfulMainLogoAndBackgroundImage(path: {eq: "/home"}) {
        logo {
          fluid(quality:100) {
            src
          }
        }
      }
      allContentfulServices(sort: {fields: heading}) {
        edges {
          node {
            id
            slug
            heading
          }
        }
      }
    }
  `)
  return (
    <div
      className="nav-component"
      style={{
        borderBottomStyle: "solid",
        borderColor: "#ffcc00",
        borderWidth: 14,
      }}
    >
      <Navbar collapseOnSelect bg="light" variant="light" expand="lg">
        <Navbar.Brand className="ml-lg-5 mx-auto" href="/">
          <img
            src={data.contentfulMainLogoAndBackgroundImage.logo.fluid.src}
            height="auto"
            className="d-inline-block ml-5 pl-lg-1 logo mt-2 mb-2 imgStyle container"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle style={{ border: "none" }} aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse className="ml-5" id="responsive-navbar-nav">
          <Nav className="ml-lg-auto mt-3 mr-5 pr-lg-5 text-center ">
            <Link to="/" className="navStyle mr-2" activeClassName="active">
              <span style={{fontWeight: 700}}>Home</span>
            </Link>
            <Link
              className="navStyle mr-2"
              to="/AboutUs"
              activeClassName="active"
            >
              <span style={{fontWeight: 700}}>About Us</span>
            </Link>
            <div
              style={{
                cursor: "pointer"
              }}
              className="navStyle mr-2 dropdownbtn"
            // activeClassName="active"
            >
              <span style={{fontWeight: 700}}>Services</span>
              <div className="dropdown-content">
                <div className="row mt-5 mb-5 ml-2 mr-2">

                  <div className="column col-lg-3 col-md-6 col-sm-6">
                    {
                      data.allContentfulServices.edges.slice(0, 6).map((edge) => {
                        return (
                          <Link to={"/services/" + edge.node.slug} key={edge.node.id} activeClassName="active">
                            <span>{edge.node.heading}</span>
                          </Link>
                        )
                      })
                    }
                  </div>
                  <div className="column col-lg-3 col-md-6 col-sm-6">
                    {
                      data.allContentfulServices.edges.slice(6, 12).map((edge) => {
                        return (
                          <Link to={"/services/" + edge.node.slug} key={edge.node.id} activeClassName="active">
                            <span>{edge.node.heading}</span>
                          </Link>
                        )
                      })
                    }
                  </div>
                  <div className="column col-lg-3 col-md-6 col-sm-6">
                    {
                      data.allContentfulServices.edges.slice(12, 18).map((edge) => {
                        return (
                          <Link to={"/services/" + edge.node.slug} key={edge.node.id} activeClassName="active">
                            <span>{edge.node.heading}</span>
                          </Link>
                        )
                      })
                    }
                  </div>
                  <div className="column col-lg-3 col-md-6 col-sm-6">
                    {
                      data.allContentfulServices.edges.slice(18, 24).map((edge) => {
                        return (
                          <Link to={"/services/" + edge.node.slug} key={edge.node.id} activeClassName="active">
                            <span>{edge.node.heading}</span>
                          </Link>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
            <Link
              className="navStyle mr-2"
              to="/OurTeam"
              activeClassName="active"
            >
              <span style={{fontWeight: 700}}>Our Team</span>
            </Link>
            <Link
              className="navStyle mr-2"
              to="/Updates"
              activeClassName="active"
            >
              <span style={{fontWeight: 700}}>Updates</span>
            </Link>
            <Link
              className="navStyle mr-2"
              to="/Careers"
              activeClassName="active"
            >
              <span style={{fontWeight: 700}}>Careers</span>
            </Link>
            <Link
              className="navStyle mr-2"
              to="/OurOffices"
              activeClassName="active"
            >
              <span style={{fontWeight: 700}}>Our Offices</span>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}
export default NavComponent

